import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { deleteBanner, fetchBannerfalse } from '../../store/bannerSlice';
import { useNavigate } from 'react-router-dom';
import { updatebussinessstatus } from '../../store/categorySlice';

const Banners = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checkedStates, setCheckedStates] = useState([]);
    const { data: banner, status } = useSelector((state) => state.banner);

    useEffect(() => {
        dispatch(fetchBannerfalse());
    }, [dispatch]);

    useEffect(() => {
        if (banner.length > 0) {
            const initialCheckedStates = banner?.map(item => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [banner]);

    const toggleSwitch = async (index, categoryId, currentStatus) => {
        const newStatus = currentStatus === "pending" ? "approved" : "pending";

        // Update UI immediately
        setCheckedStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = newStatus;
            return newStates;
        });

        try {
            await dispatch(updatebussinessstatus({ id: categoryId, status: newStatus }));
        } catch (error) {
            console.error('Error updating status:', error);
            // Revert to previous status on error
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });
            message.error('Failed to update category status.');
        }
    };

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                destroy: true,
            });
        }, 1000);
    });

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteBanner(id));
            }
        });
    };

    // Filter for approved status
    const filteredBanners = banner.filter(item => item.status === "approved");

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Business
                    </h2>
                </div>
                <div className="row" id="banner-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Business
                                            <span className="badge badge-soft-dark radius-50 fz-12">{filteredBanners.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                           {filteredBanners.length > 0 ? <div className="table-responsive">
                                <table
                                    id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th className="pl-xl-5">SL</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBanners.length > 0 ? filteredBanners?.map((i, index) => (
                                            <tr key={i._id}>
                                                <td className="pl-xl-5">{index + 1}</td>
                                                <td>{i.firstName}</td>
                                                <td>{i.email}</td>
                                                <td>{i.phoneNumber}</td>
                                                <td className="text-center">
                                                    <div style={{
                                                        position: 'relative',
                                                        display: 'inline-block',
                                                        width: '40px',
                                                        height: '20px',
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkedStates[index] === "approved"}
                                                            onChange={() => toggleSwitch(index, i._id, i.status)}
                                                            style={{
                                                                opacity: 0,
                                                                width: 0,
                                                                height: 0,
                                                            }}
                                                        />
                                                        <div
                                                            onClick={() => toggleSwitch(index, i._id, i.status)}
                                                            style={{
                                                                position: 'absolute',
                                                                cursor: 'pointer',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                backgroundColor: checkedStates[index] === "approved" ? '#2196F3' : '#ccc',
                                                                transition: '.4s',
                                                                borderRadius: '20px',
                                                            }}
                                                        >
                                                            <div style={{
                                                                position: 'absolute',
                                                                content: '""',
                                                                height: '16px',
                                                                width: '16px',
                                                                left: checkedStates[index] === "approved" ? '22px' : '2px',
                                                                bottom: '2px',
                                                                backgroundColor: 'white',
                                                                transition: '.4s',
                                                                borderRadius: '50%',
                                                            }}></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex gap-3 align-items-center">
        <button
            onClick={() => onDelete(i._id)}
            className="btn btn-outline-danger square-btn btn-sm mr-1"
            title="Delete"
        >
            <i className="bi bi-trash" />
        </button>
        
        <button
            onClick={() =>(navigate(`/riderdetail/${i._id}`))} // Changed to onView for clarity
            className="btn btn-outline-primary square-btn btn-sm" // Use 'btn-outline-primary' for blue
            title="View"
        >
            <i className="bi bi-eye" />
        </button>
    </div>
</td>
                                            </tr>
                                        )) : <tr><td colSpan="6" className="text-center">No data available</td></tr>}
                                    </tbody>
                                </table>
                            </div>: <div className='text-center'>No data available</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banners;
