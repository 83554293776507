import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { deleteproduct, fetchsubAdvertisement, updateproduct } from '../../store/productSlice';
import { useNavigate } from 'react-router-dom';
const NewProducts = () => {
    const dispatch = useDispatch();
    const { data: product } = useSelector((state) => state?.product);
    const [checkedStates, setCheckedStates] = useState([]);
    const navigate =useNavigate()
    useEffect(() => {
        dispatch(fetchsubAdvertisement());
    }, [dispatch]);

    useEffect(() => {
        if (product && product.length > 0) {
            // Set initial checked states based on filtered products
            const initialCheckedStates = product.filter(item => item.status === true).map(item => true);
            setCheckedStates(initialCheckedStates);
        }
    }, [product]);

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                bDestroy: true,
            });
        }, 1000);
    });

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteproduct(id));
            }
        });
    };

    const toggleStatus = (index, id) => {
        const newStatus = !checkedStates[index];
        setCheckedStates((prevStates) => {
            const updatedStates = [...prevStates];
            updatedStates[index] = newStatus;
            return updatedStates;
        });

        dispatch(updateproduct(id, newStatus));
    };

    const filteredProducts = product?.filter(item => item.status === true) || [];

    return (
        <div className='mt-5 toppp' style={{ marginTop: '84px' }}>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-0 text-capitalize d-flex gap-2">
                        <img
                            src="https://6valley.6amtech.com/public/assets/back-end/img/inhouse-product-list.png"
                            alt=""
                        />
                        Sub Advertisement
                        <span className="badge badge-soft-dark radius-50 fz-14 ml-1">2</span>
                    </h2>
                </div>
                <div className="row mt-20">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="card-body p-0">
                               {filteredProducts.length > 0 ? <div className="table-responsive">
                                    <table
                                        id="allOrder"
                                        style={{ textAlign: "left" }}
                                        className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                    >
                                        <thead className="thead-light thead-50 text-capitalize">
                                            <tr>
                                                <th>SL</th>
                                                <th>Name</th>
                                                <th>Service Name</th>
                                                <th>Image</th>
                                                <th>Payment Status</th>
                                                <th>Discount</th>
                                                <th>Status</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredProducts.length > 0 ? filteredProducts.map((item, index) => (
                                                <tr key={item._id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item?.ownerName}</td>
                                                    <td>{item?.category?.name}</td>
                                                    <td>
                                                        <img
                                                            src={item?.restaurantLogo}
                                                            className="avatar border"
                                                            alt=""
                                                            style={{ width: '50px', height: '50px' }}
                                                        />
                                                    </td>
                                                    <td>{item?.paymentStatus ? 'True' : 'False'}</td>
                                                    <td>{item?.discountPercentage}</td>
                                                    <td>
                                                        <div style={{
                                                            position: 'relative',
                                                            display: 'inline-block',
                                                            width: '40px',
                                                            height: '20px',
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkedStates[index] === true} // Reflects actual status
                                                                onChange={() => toggleStatus(index, item._id)}
                                                                style={{
                                                                    opacity: 0,
                                                                    width: 0,
                                                                    height: 0,
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => toggleStatus(index, item._id)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    cursor: 'pointer',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    backgroundColor: checkedStates[index] ? '#2196F3' : '#ccc',
                                                                    transition: '.4s',
                                                                    borderRadius: '20px',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    content: '""',
                                                                    height: '16px',
                                                                    width: '16px',
                                                                    left: checkedStates[index] ? '22px' : '2px',
                                                                    bottom: '2px',
                                                                    backgroundColor: 'white',
                                                                    transition: '.4s',
                                                                    borderRadius: '50%',
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div className="d-flex gap-3 align-items-center">
        <button
            onClick={() => onDelete(item._id)}
            className="btn btn-outline-danger square-btn btn-sm mr-1"
            title="Delete"
        >
            <i className="bi bi-trash" />
        </button>
        
        <button
            onClick={() =>(navigate(`/sellerdetails/${item._id}`))} // Changed to onView for clarity
            className="btn btn-outline-primary square-btn btn-sm" // Use 'btn-outline-primary' for blue
            title="View"
        >
            <i className="bi bi-eye" />
        </button>
    </div>
</td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan="8" className="text-center">No products available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>:<div className='text-center'>No data available</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewProducts;
