import React, { useEffect, useState } from 'react';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { deleteBanner, fetchdeals } from './../../store/bannerSlice';
import { useNavigate } from 'react-router-dom';
import { updatedealstatus } from '../../store/categorySlice';

const Banners = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checkedStates, setCheckedStates] = useState([]);
    const { data: banner, status } = useSelector((state) => state.banner);
    
    useEffect(() => {
        dispatch(fetchdeals());
    }, []);
  
    useEffect(() => {
        if (banner.length > 0) {
            const initialCheckedStates = banner.map(item => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [banner]);

    const toggleSwitch = async (index, categoryId, currentStatus) => {
        const newStatus = !checkedStates[index];

        try {
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            await dispatch(updatedealstatus({ id: categoryId, discountActive: newStatus }));
        } catch (error) {
            console.error('Error updating status:', error);
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus;
                return newStates;
            });
            message.error('Failed to update category status.');
        }
    };

    $(document).ready(function () {
        setTimeout(function () {
            $('#allOrder').DataTable({
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                destroy: true,
            });
        }, 1000);
    });

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk: () => {
                dispatch(deleteBanner(id));
            }
        });
    };

    // Filter the banners with discountOfferingPercentage greater than 0
    const filteredBanners = banner.filter(i => i.discountPercentage  > 0);

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <div className="mb-3">
                    <h2 className="h1 mb-1 text-capitalize d-flex align-items-center gap-2">
                        Business
                    </h2>
                </div>
                <div className="row" id="banner-table">
                    <div className="col-md-12">
                        <div className="card p-3">
                            <div className="px-3 py-4">
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-6 mb-2 mb-md-0">
                                        <h5 className="mb-0 text-capitalize d-flex gap-2">
                                            Business
                                            <span className="badge badge-soft-dark radius-50 fz-12">{filteredBanners.length}</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                           {filteredBanners.length > 0 ? <div className="table-responsive">
                                <table
                                    id="allOrder"
                                    style={{ textAlign: "left" }}
                                    className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table w-100"
                                >
                                    <thead className="thead-light thead-50 text-capitalize">
                                        <tr>
                                            <th className="pl-xl-5">SL</th>
                                            <th>Owner</th>
                                            <th>Resturant</th>
                                            <th>Logo</th>
                                            <th>Discount</th>
                                            {/* <th className="text-center">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBanners.length > 0 ? filteredBanners.map((i, index) => (
                                            <tr key={i._id}>
                                                <td className="pl-xl-5">{index + 1}</td>
                                                <td>{i.
ownerName
}</td>
<td>{i.restaurantName}</td>
                                                <td>
                                                    <img
                                                        className="ratio-4:1"
                                                        width={80}
                                                        src={i.
                                                            restaurantLogo
                                                            }
                                                        alt={i.ownerName}
                                                    />
                                                </td>
                                                <td>{i.discountPercentage}</td>
                                               
                                                
                                                {/* <td>
                                                    <div className="d-flex justify-content-center gap-2">
                                                        <button
                                                            className="btn btn-outline-danger square-btn btn-sm mr-1"
                                                            onClick={() => onDelete(i._id)}
                                                            title="Delete"
                                                        >
                                                            <i className="bi bi-trash" />
                                                        </button>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        )) : <tr><td colSpan="6" className="text-center">No data available</td></tr>}
                                    </tbody>
                                </table>
                            </div> :<div className='text-center'>No available data</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banners;
