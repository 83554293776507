import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
import Loader from '../Loader';
import axios from 'axios';


const Uploadplans = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.category);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [duration, setDuration] = useState('');
    const [cost, setCost] = useState('');
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !price || !duration || !cost) {
            message.error('All fields are required.');
            return;
        }

        try {
            setLoading(true);
            const payload = { name, price, duration, cost };
            
            const response = await axios.post('http://5.161.110.71:7000/common/featured', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            message.success('Category created successfully!');
            // navigate('/path-to-redirect-after-success'); // Change this to your desired route

        } catch (error) {
            const errorMessage = error.response ? error.response.data.message : error.message;
            message.error(`Failed to create category: ${errorMessage}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0">Category Setup</h2>

                <form onSubmit={handleSubmit}>
                    <div className="card mt-2 p-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group lang_form" id="en-form">
                                    <label className="title-color">
                                        Feature Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Feature Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="title-color">
                                        Price <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        name="price"
                                        className="form-control"
                                        placeholder="Price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="title-color">
                                        Duration (in days) <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        name="duration"
                                        className="form-control"
                                        placeholder="Duration"
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="title-color">
                                        Cost <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        name="cost"
                                        className="form-control"
                                        placeholder="Cost"
                                        value={cost}
                                        onChange={(e) => setCost(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-end mt-3">
                            <button type="reset" className="btn btn-secondary">
                                Reset
                            </button>
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? <Loader /> : 'Submit'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Uploadplans;
