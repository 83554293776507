import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Sidebar({ width, display }) {
    const [activeCollapse, setActiveCollapse] = useState(null);

    const NavLinkStyle = ({ isActive }) => ({
        backgroundColor: isActive ? 'white' : '',
        borderLeft: isActive ? '5px solid #0c63e4' : 'none'
    });

    const handleCollapse = (collapseId) => {
        setActiveCollapse((prev) => (prev === collapseId ? null : collapseId));
    };

    return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{ width, display }}>
            <div className="sidebar-header">
                <img src={require("../download.png")} className="logo-icon" alt="logo icon" />
                <h4 className="logo-text">Ashh Karo</h4>
            </div>
            <ul className="metismenu">
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="/"
                        onClick={() => setActiveCollapse(null)}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-house-fill" />
                        </div>
                        <div className="menu-title">Dashboard</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="/users"
                        onClick={() => setActiveCollapse(null)}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-box-arrow-left" />
                        </div>
                        <div className="menu-title">Users</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseSellers')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-border-width" />
                        </div>
                        <div className="menu-title">Sellers</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseSellers' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/seller_verification' style={NavLinkStyle}>
                                    <i className="bi bi-check-all px-3" /> Verification
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/sellers' style={NavLinkStyle}>
                                    <i className="bi bi-arrow-clockwise px-3" /> All Sellers
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                {/* <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseDeals')}
                    >
                        <div className="parent-icon">
                            <img src="data:image/png;base64,...base64-image-here..." alt="deals icon" />
                        </div>
                        <div className="menu-title">Today Deals</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseDeals' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/deals_approvals' style={NavLinkStyle}>
                                    Approval Request
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/deals' style={NavLinkStyle}>
                                    All Deals
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li> */}
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseDeals')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-grid-fill" />
                        </div>
                        <div className="menu-title">Today Deals</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseDeals' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to="/deals_approvals" style={NavLinkStyle}>
                                All deals
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/deals" style={NavLinkStyle}>
                                    All Deals
                                </NavLink>
                            </li> */}
                            
                        </ul>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseCategories')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-grid-fill" />
                        </div>
                        <div className="menu-title">Category</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseCategories' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to="/category" style={NavLinkStyle}>
                                    Categories
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/all_categories" style={NavLinkStyle}>
                                    All Categories
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/subCategory" style={NavLinkStyle}>
                                    Sub Category
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseWithdraws')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Withdraws Delivery & Sellers</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseWithdraws' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/withdraws' style={NavLinkStyle}>
                                    Approvals
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/withdraws_approval' style={NavLinkStyle}>
                                    All Withdraws
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseDelivery')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Delivery Boy</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseDelivery' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/verification' style={NavLinkStyle}>
                                    Verification
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/delieveryboys' style={NavLinkStyle}>
                                    Delivery Boys
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseFeatured')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Featured Plans</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseFeatured' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/Featured_plans' style={NavLinkStyle}>
                                    Form
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/All_featured' style={NavLinkStyle}>
                                    All Plans
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="/food"
                        onClick={() => setActiveCollapse(null)}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-box-arrow-left" />
                        </div>
                        <div className="menu-title">All Foods</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="/Orders"
                        onClick={() => setActiveCollapse(null)}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-box-arrow-left" />
                        </div>
                        <div className="menu-title">Orders</div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="javascript:void(0)"
                        onClick={() => handleCollapse('collapseFeatured')}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-award-fill" />
                        </div>
                        <div className="menu-title">Payments</div>
                    </NavLink>
                    <div className={`collapse ${activeCollapse === 'collapseFeatured' ? 'show' : ''}`}>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/order_payments' style={NavLinkStyle}>
                                    Order payments
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/resturant_payments' style={NavLinkStyle}>
                                   Resturant Payments
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/featured_payments' style={NavLinkStyle}>
                                   Featured Payments
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <NavLink
                        style={NavLinkStyle}
                        to="/"
                        onClick={() => setActiveCollapse(null)}
                    >
                        <div className="parent-icon">
                            <i className="bi bi-box-arrow-left" />
                        </div>
                        <div className="menu-title">Logout</div>
                    </NavLink>
                </li>
                <li style={{ display: "flex" }}>
                    <a href="https://wolftechs.pk/">Created by <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>Wolf Tech</span></a>
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;
