import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
// import Modals from '../Modals/Modals';
import UpdateCategorey from '../UpdateCategory/UpdateCategory';

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import {Modal, message } from 'antd';
import { createcategory, deletecategory, fetchcategory,updatecategorystatus ,getByIdcategory} from '../../store/categorySlice';
import Loader from '../Loader';


// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import axios from 'axios';
// import { Modal, message } from 'antd';
// import { fetchcategory, createcategory, deletecategory, updatecategory } from '../../store/categorySlice';
// import Loader from '../Loader';

const Category = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.category);
    const [file, setFile] = useState(null);
    const [categoryname, setCategoryname] = useState('');
    const [uploadStatus, setUploadStatus] = useState('');
    const [checkedStates, setCheckedStates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchcategory());
    }, [dispatch]);
  console.log(data)
    useEffect(() => {
        if (data && data.length > 0) {
            const initialCheckedStates = data.map((item) => item.status);
            setCheckedStates(initialCheckedStates);
        }
    }, [data]);

    const toggleSwitch = async (index, categoryId, currentStatus) => {
        const newStatus = !checkedStates[index]; // Toggle the current state
        console.log(categoryId,newStatus,currentStatus)
        try {
            // Update UI optimistically
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = newStatus;
                return newStates;
            });

            // Update backend via Redux action
            await dispatch(updatecategorystatus({ id: categoryId, status: newStatus }));

            // Handle success (if needed)
            console.log('Status updated successfully');
        } catch (error) {
            // Handle error (rollback UI changes if needed)
            console.error('Error updating status:', error);

            // Revert UI changes on failure
            setCheckedStates((prevStates) => {
                const newStates = [...prevStates];
                newStates[index] = currentStatus; // Revert to previous status
                return newStates;
            });

            // Optionally show error message
            message.error('Failed to update category status.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!categoryname) {
            message.error('Category name is required');
            return;
        }
        if (!file) {
            message.error('Please upload the image first');
            return;
        }

        // const categoryData = new FormData();
        // categoryData.append('image', file);
        // categoryData.append('name', categoryname);

        try {
            setLoading(true);
            await dispatch(createcategory({image:file,name:categoryname}));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        
            message.error('Failed to create category.');
        }
    };

    const handleUpload = async (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        try {
            const response = await axios.post('http://5.161.110.71:7000/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
               
            });
            console.log(response?.data?.data)

              setFile(response?.data?.data)
            message.success('Image uploaded successfully!');
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Failed to upload image.');
        }
    };

    console.log(file)
 

    return (
        <div className='mt-5 toppp'>
            <div className="content container-fluid mt-5">
                <h2 className="h1 mb-0 d-flex gap-10">
                    <img src="https://6valley.6amtech.com/public/assets/back-end/img/brand-setup.png" alt="" />
                    Category Setup
                </h2>

                <form onSubmit={handleSubmit}>
                    <div className="card mt-2 p-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group lang_form" id="en-form">
                                    <label className="title-color">
                                        Category Name<span className="text-danger">*</span> (EN)
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        placeholder="New Category"
                                        required=""
                                        value={categoryname}
                                        onChange={(event) => setCategoryname(event.target.value)}
                                    />
                                </div>
                                
                                <div>
                                <label className="title-color">Category Image</label>
                                <div className="custom-file text-left">
                                    {file ? <img style={{ display: 'block' }} width={100} src={file} alt='' /> : (
                                        <img
                                            className="upload-img-view"
                                            id="viewer"
                                            src="https://6valley.6amtech.com/public/assets/back-end/img/900x400/img1.jpg"
                                            alt="image"
                                        />
                                    )}
                                </div>

                                </div>
                                   

                                <div className="from_part_2" style={{ marginTop: '100px' }}>
                                <label className="custom-file-label" htmlFor="customFileEg1">
                                            Choose File
                                        </label>
                                    <div className="custom-file text-left">
                                        <input
                                            type="file"
                                            name="image"
                                            id="image"
                                            className="custom-file-input"
                                            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                                            required=""
                                            onChange={(e) => handleUpload(e)}
                                        />
                                       
                                    </div>
                                </div>
                            </div>
                           
                        </div>

                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <button type="reset" id="reset" className="btn btn-secondary">
                                Reset
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {loading ? <Loader /> : 'Submit'}
                            </button>
                        </div>
                    </div>
                </form>

                
            </div>
        </div>
    );
};

export default Category;


// Category.js

